@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    min-height: 100vh;
}
#main-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.wrapper {
    background-color: #ecf0f3;
    text-align: right;
    overflow-x: hidden;
    height: 100%;
}
html,
body {
    background-color: #ecf0f3;
    overflow-x: hidden;
}
* {
    font-family: CAIRO_SEMIBOLD;
    transition: all 250ms ease-in-out;
}
*:focus,
*:active {
    outline: none !important;
    border: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.bg-morabaa-red {
    background-color: #c0202f;
}
.bg-neu-white {
    background-color: #ecf0f3;
}
.neu-card {
    border-radius: 10px;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    /* background: linear-gradient(145deg, #d4d8db, #fdffff); */
    background: #ffffff;
    box-shadow: 8px 8px 8px #eceef0, 8px 8px 8px #ffffff;
}
.neu-card:hover {
    background: #ecf0f3;
    box-shadow: 10px 10px 20px #c9cccf, 10px 10px 20px #ffffff;
}
.MainHeader {
    background: #525c65;
    display: flex;
    height: 90px;
}
.logoHeader {
    background: #65b1c7;
    display: flex;
    height: 90px;
    /* width: 18%; */
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #ffffff;
}
.morabaa {
    font-family: GE-Flow-Regular;
}

@font-face {
    font-family: "GE-Flow-Regular";
    src: url(Assets/fonts/GE-Flow-Bold.otf);
    font-style: normal;
}
@font-face {
    font-family: "CAIRO_SEMIBOLD";
    src: url(Assets/fonts/CAIRO_SEMIBOLD.TTF);
    font-style: normal;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 25px;
    top: 30px;
    float: right;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    float: right;
}

/* General sidebar styles */
.bm-menu {
    background: #fff;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
    color: #ffffff;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.logs-card {
    margin: 5px;
    /* padding: 10px; */
    overflow: hidden;
    border-radius: 16px;
    font-family: CAIRO_SEMIBOLD;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    /* padding-bottom: 15px; */
    /* background-color: #f0f0f0; */
    background-color: #fff;
}
.logs-card-text {
    font-size: 16px;
}
.logs-card-title {
    font-size: 16px;
    /* min-width: 100px; */
    text-align: left;
    color: rgb(185, 28, 28);
    /* : 0px 0px 8px rgba(0, 0, 0, 0.16); */
}

.logs-card-header {
    display: flex;
    text-align: center;
    justify-content: space-between;
    background-color: rgb(240, 240, 240);
    margin-bottom: 16px;
    align-items: center;
    font-size: 18px;
    padding: 10px 15px;
}

.logs-level {
    color: #fff;
    min-width: 90px;
    padding: 5px 0;
    border-radius: 10px;
}

.logs-card-message {
    padding: 30px;
    /* margin-top: 20px; */
    /* background-color: #fff; */
}

.Grid {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
}

@media screen and (min-width: 1280px) {
    .Grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

/* @media screen and (min-width: 1900px) {
    .Grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
} */

.input-conatiner {
    display: flex;
    flex-direction: column;
    margin: 8px;
    /* align-items: center; */
    /* margin: 10px 5px; */
}

.input-lable {
    font-size: 18px;
    padding-bottom: 4px;
    padding-right: 8px;
    color: rgb(75, 85, 99);
}
.input-field {
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.drowp-field {
    padding: 6px 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    background-color: #525c65;
    color: #fff;
}

.green-btn {
    background-color: rgb(16, 185, 129);
    font-family: CAIRO_SEMIBOLD;
    padding: 8px 20px;
    font-size: 18px;
    color: #fff;
    border-radius: 10px;
    margin: 8px;
}

.logs-card-lables {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
}

/* 
@media screen and (max-width: 730px) {
    .Grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
} */
